import { config } from "@config/config";

export const defaultMetaTagData = {
    metaTitle: "VittOris",
    metaDescription: "Přihláškový systém napojený na ORIS by Vitton",
    fbAppId: config.FACEBOOK_APP_ID,
    image: "", // relative eg. "/static/fb-og-image.jpg"
    keywords: "",
    siteName: "VittOris",
    type: "website",
    imageWidth: 1200,
    imageHeight: 630,
} as const;
