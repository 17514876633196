import { routeToUrl } from "@app-routes";
import { LoginLayout } from "@main-zone/components/login-layout/login-layout";
import { LoginForm } from "@main-zone/forms/login-form/login-form";
import { useIsMounted } from "@uxf/core-react/hooks/use-is-mounted";
import { Button } from "@uxf/ui/button";
import { Loader } from "@uxf/ui/loader/loader";
import { TextLink } from "@uxf/ui/text-link";
import Router from "next/router";

export function LoginPage() {
    const isMounted = useIsMounted();

    return (
        <LoginLayout pageTitle="Přihlášení" title="Přihlášení">
            {isMounted ? (
                <div className="space-y-6">
                    <LoginForm />
                    <p className="text-center">
                        {/*TODO různé maily dle klubu -> je třeba nová query*/}
                        Pokud jsi členem oddílu OOB TJ Turnov a na e-mail ti nepřišla pozvánka, nebo za oddíl hostuješ,
                        napiš na <TextLink href="mailTo:admin@vittoris.tur.cz">admin@vittoris.tur.cz</TextLink> (nebo
                        přímo Vittonovi) o její zaslání.
                    </p>
                    <Button
                        isFullWidth
                        variant="outlined"
                        onClick={() => {
                            Router.push(routeToUrl("main-zone/guest-registration"));
                        }}
                    >
                        Registrovat se jako host
                    </Button>
                </div>
            ) : (
                <div className="flex h-80 items-center justify-center">
                    <Loader />
                </div>
            )}
        </LoginLayout>
    );
}
