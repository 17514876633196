import { FieldValue } from "react-hook-form";

export const validateRegNumber = (value: FieldValue<string | any>) => {
    if (!value) {
        return undefined;
    }

    if (!/^(\w{3}\d{4})$/.test(value)) {
        return "Zadejte platné registrační číslo";
    }

    return undefined;
};
