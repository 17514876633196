import { config } from "@config/config";
import { ICONS_VERSION } from "@config/icons";
import Head from "next/head";
import { memo } from "react";

export const Prefetch = memo(() => (
    <Head>
        {config.GTM_ID && (
            <>
                <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com" />
                <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com" />
            </>
        )}
        <link
            as="image"
            crossOrigin="anonymous"
            href={`/icons-generated/_icon-sprite.svg?v=${ICONS_VERSION}`}
            rel="prefetch"
        />
    </Head>
));

Prefetch.displayName = "Prefetch";
