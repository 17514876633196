import { useApolloClient } from "@apollo/client";
import { routeToUrl, useQueryParams } from "@app-routes";
import { LAST_LOGIN_COOKIE_NAME } from "@config/global-constants";
import { useLoginMutation } from "@gql-schema";
import { ErrorService } from "@shared/services/error-service";
import { validateLoginUsername } from "@shared/utils/validators/validate-login-username";
import { Cookie } from "@uxf/core/cookie";
import { Form } from "@uxf/form/form";
import { TextInput } from "@uxf/form/text-input";
import { queryParamToString } from "@uxf/router";
import { Button } from "@uxf/ui/button";
import { TextLink } from "@uxf/ui/text-link";
import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

export interface LoginFormData {
    username: string;
    password: string;
}

export const LoginForm: FC = () => {
    const [formDisabled, setFormDisabled] = useState(true);

    useEffect(() => {
        setFormDisabled(false);
    }, []);

    const apolloClient = useApolloClient();
    const [login] = useLoginMutation();
    const { push } = useRouter();
    const params = useQueryParams<"main-zone/login">();
    const redirectTo = queryParamToString(params.redirect);

    const onSubmit: SubmitHandler<LoginFormData> = async (values) => {
        try {
            await login({ variables: values });
            Cookie.create().set(LAST_LOGIN_COOKIE_NAME, "1", 1000 * 60 * 60 * 24 * 365);
            await apolloClient.cache.reset();
            await push(redirectTo || routeToUrl("main-zone/index"));
        } catch (e) {
            ErrorService.handleErrorWithMessage(e, "Přihlášení se nezdařilo.");
        }
    };

    const formApi = useForm<LoginFormData>();

    return (
        <Form formApi={formApi} onSubmit={onSubmit} id="login-form">
            <div className="space-y-6">
                <TextInput
                    control={formApi.control}
                    isDisabled={formDisabled}
                    isRequired
                    label="E-mail / Registrační číslo"
                    name="username"
                    placeholder="Zadejte svůj e-mail, nebo registrační číslo"
                    rules={{ validate: validateLoginUsername }}
                />
                <div>
                    <TextInput
                        control={formApi.control}
                        isDisabled={formDisabled}
                        isRequired
                        label="Heslo"
                        name="password"
                        placeholder="Zadejte heslo"
                        type="password"
                    />
                    <div className="text-right">
                        <TextLink
                            className="uxf-typo-medium2 text-lightMedium"
                            disabled={formApi.formState.isSubmitting}
                            href={routeToUrl("main-zone/forgotten-password")}
                        >
                            Zapomněli jste heslo?
                        </TextLink>
                    </div>
                </div>
                <Button isFullWidth type="submit" loading={formApi.formState.isSubmitting}>
                    Přihlásit se
                </Button>
            </div>
        </Form>
    );
};
