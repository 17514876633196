import { validateRegNumber } from "@shared/utils/validators/validate-reg-number";
import { Validator } from "@uxf/core";
import { FieldValue } from "react-hook-form";

export const validateLoginUsername = (value: FieldValue<string | any>) => {
    if (!value || Validator.isEmail(value) || !validateRegNumber(value)) {
        return undefined;
    }

    return "Zadejte platný E-mail, nebo Registrační číslo";
};
