import { defaultMetaTagData } from "@config/default-meta-tag-data";
import Head from "next/head";
import { memo } from "react";

export const Favicon = memo(() => (
    <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png?v=2" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png?v=2" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png?v=2" />
        <link rel="manifest" href="/images/favicon/site.webmanifest?v=2" crossOrigin="use-credentials" />
        <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg?v=2" color="#ea7244" />
        <link rel="shortcut icon" href="/images/favicon/favicon.ico?v=2" />
        <meta name="apple-mobile-web-app-title" content={defaultMetaTagData.siteName} />
        <meta name="application-name" content={defaultMetaTagData.siteName} />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="msapplication-config" content="/images/favicon/browserconfig.xml?v=2" />
        <meta name="theme-color" content="#ffffff" />
    </Head>
));

Favicon.displayName = "Favicon";
